import React, {useState, useEffect, useCallback} from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import InactivityModal from "./InactivityModal";

const Room = ({roomName, token, handleLogout, shouldRecord, onDownload}) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [audioMuted, setAudioMuted] = useState(false);
    const [videoDisabled, setVideoDisabled] = useState(false);
    const [isInactiveModalVisible, setIsInactiveModalVisible] = useState(false);

    const [loading, setLoading] = useState(false);

    const endCall = useCallback(() => {
        if (room) {
            room.disconnect();
        }
        handleLogout();
    }, [room, handleLogout]);

    useEffect(() => {
        const showInactiveModal = setTimeout(() => {
            setIsInactiveModalVisible(true);
        }, 45 * 60 * 1000);

        const autoEndCall = setTimeout(() => {
            if (isInactiveModalVisible) {
                endCall();
            }
        }, 3 * 60 * 1000);

        return () => {
            clearTimeout(showInactiveModal);
            clearTimeout(autoEndCall);
        };
    }, [endCall, isInactiveModalVisible]);

    useEffect(() => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName,

        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        });
        Video.Logger.setLevel('debug');

        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token]);

    const toggleMute = () => {
        if (room) {
            room.localParticipant.audioTracks.forEach(publication => {
                if (audioMuted) {
                    publication.track.enable();
                } else {
                    publication.track.disable();
                }
            });

            setAudioMuted(!audioMuted);
        }
    };


    const continueCall = () => {
        setIsInactiveModalVisible(false);
    };


    const toggleVideo = () => {
        if (room) {
            room.localParticipant.videoTracks.forEach(publication => {
                if (videoDisabled) {
                    publication.track.enable();
                } else {
                    publication.track.disable();
                }
            });

            setVideoDisabled(!videoDisabled);
        }
    };

    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant}/>
    ));

    return (
        <div className="appContainer">
            <main>
                <div className="room">
                    <div className="local-participant">
                        {room ? (
                            <Participant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="remote-participants">{remoteParticipants}</div>

                </div>
            </main>
            <footer>
                <div className="buttonContainer">
                    <button className="iconButton" onClick={endCall}>
                        <i className="fas fa-phone-slash"/>
                    </button>
                    <button className="iconButton" onClick={toggleMute}>
                        {audioMuted ? <i className="fas fa-microphone-slash"/> : <i className="fas fa-microphone"/>}
                    </button>
                    <button className="iconButton" onClick={toggleVideo}>
                        {videoDisabled ? <i className="fas fa-video-slash"/> : <i className="fas fa-video"/>}
                    </button>
                    {shouldRecord ? <div style={{color: 'white', cursor: 'pointer'}} onClick={() => {
                        setLoading(true)
                        if (room) {
                            room.disconnect();
                        }
                        onDownload()
                        setTimeout(()=>{
                            handleLogout();
                        },3000)
                    }}>
                        {loading ? <div className={"spinnerContainer"}>
                            <div style={{marginRight: 10}} className="spinner"></div>
                            Downloading...</div> : <div className={"spinnerContainer"}>Leave and download record</div>}
                    </div> : null}
                </div>

            </footer>
            {isInactiveModalVisible && <InactivityModal onContinue={continueCall} onEnd={endCall}/>}

        </div>
    );
};

export default Room;
