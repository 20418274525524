import React from 'react';

function InactivityModal({ onContinue, onEnd }) {
    return (
        <div className="inactivityModal-overlay">
            <div className="inactivityModal">
                <h2>Inactivity Detected</h2>
                <p>Do you wish to continue the call?</p>
                <button onClick={onContinue}>Continue</button>
                <button onClick={onEnd}>End Call</button>
            </div>
        </div>
    );
}

export default InactivityModal;
