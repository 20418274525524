import React, {useCallback, useState} from 'react';
import {getToken} from "./api";
import Room from "./Room";
import axios from "axios";

const downloadUrl = 'https://video-chat-api.uc.r.appspot.com/video/recording';
const recordingURL = 'https://video-chat-api.uc.r.appspot.com/downloadRecording';

function VideoChat() {
    const [token, setToken] = useState(null);
    const [roomSid, setRoomSid] = useState(null);
    const [room, setRoom] = useState(null);
    const [shouldRecord, setShouldRecord] = useState(false);

    const handleLogin = async () => {
        const params = new URLSearchParams(window.location.search);
        const room = params.get('conversation');
        const identity = params.get('identity');
        setRoom(room)

        const token = await getToken(identity, room, shouldRecord)
        setToken(token.token)
        setRoomSid(token.roomSid)
    }

    const handleLogout = useCallback(event => {
        setToken(null);
    }, []);

    const downloadFileFromURL = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", ""); // El atributo 'download' sugiere al navegador que descargue el recurso en lugar de navegar a él.
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const downloadFiles = async () => {
        try {
            const res = await axios.get(downloadUrl + `/?roomSid=${roomSid}`);

          setTimeout(async () => {
              const urls = [
                  res.data.url1,
                  res.data.url2,
                  res.data.url3,
                  res.data.url4
              ].filter(urlBase => urlBase)
                  .map(urlBase => urlBase + '/Media');

              for (const url of urls) {
                  try {
                      const response = await axios.get(recordingURL + `/?recordingURL=${url}`);
                      console.log('res', response.data);
                      downloadFileFromURL(response.data);
                  } catch (innerError) {
                      console.error('Error fetching individual URL', url, innerError);
                  }
              }
          },3000)

        } catch (error) {
            console.error('Error fetching the main download URL', error);
        }
    }

    if (token) {
        return <Room onDownload={downloadFiles} shouldRecord={shouldRecord} roomName={room} token={token} handleLogout={handleLogout}/>
    } else {
        return <div>
            <p style={{marginTop: 10}}>Record: {shouldRecord ? 'yes' : 'no'}</p>


            <button style={{width: 200, height: 50, marginTop: 50, marginBottom: 50}} onClick={handleLogin}>Join
            </button>

            <button className="iconButton" onClick={() => setShouldRecord(!shouldRecord)}>
                {shouldRecord ? <i className="fas fa-circle"/> : <i className="far fa-circle"/>}
            </button>
        </div>
    }
}

export default VideoChat;
