import './App.css';
import VideoChat from "./VideoChat";
import {useEffect} from "react";

function App() {
    const params = new URLSearchParams(window.location.search);
    const room = params.get('conversation');
    const identity = params.get('identity');

    useEffect(() => {
        function setVH() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setVH();
        window.addEventListener('resize', setVH);

        return () => {
            window.removeEventListener('resize', setVH);
        };
    }, []);

   useEffect(()=>{
       if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
           navigator.mediaDevices.getUserMedia({ video: true, audio: true })
               .then(stream => {
                   const videoElement = document.querySelector('video');
                   videoElement.srcObject = stream;
               })
               .catch(error => {
                   console.error("Error al acceder a la cámara o al micrófono:", error);
               });
       } else {
           console.error("La API mediaDevices no está disponible en este navegador.");
       }
   },[])

    if(!room || !identity){
        return <center className="appContainer">
            <h1>Qix Video Chat</h1>
            <h2>This conversationId is not valid, ask the admin again for a new link.</h2>
        </center>
    }

    return (
        <center className="appContainer">
            <h1>Qix Video Chat</h1>
            <VideoChat/>
        </center>
    );
}
export default App;
