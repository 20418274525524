import axios from "axios";

export const getToken = async (identity, room, shouldRecord) => {
    if(!identity || !room) return null

    // return await axios.get(`http://localhost:3001/video/token?identity=${phone}&room=${room}`).then((res=>{
    return await axios.get(`https://video-chat-api.uc.r.appspot.com/video/token?identity=${identity}&room=${room}&shouldRecord=${shouldRecord}`).then((res=>{
        return res.data
    }))
}
